import cookies from 'browser-cookies';
import {
  USE_AUTH0,
  IMPERSONATION_MODE
} from '@nm-utils-lib-web/authentication/src/constants/featureDetection';
import { getCookieDomain } from '@nm-utils-lib-web/cookies';

import { CONNECTIONS } from '../constants';

import eraseAuthCookies from './eraseAuthCookies';

const setAuthCookies = (impersonation, connection) => {
  const domain = getCookieDomain();
  const connectionType = connection === CONNECTIONS.CHASE_SSO ? CONNECTIONS.CHASE_SSO : CONNECTIONS.NUTMEG_OFFICE_365;

  if (!impersonation) {
    eraseAuthCookies(domain);
  }

  cookies.set(USE_AUTH0, 'true', { domain });

  if (impersonation) {
    cookies.set(IMPERSONATION_MODE, connectionType, { domain });
  }
};

export default setAuthCookies;
